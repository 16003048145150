<template>
  <section class="bg-light p-3 h-100">
    <h2>Particularités</h2>
    <hr />
    <article class="mt-3">
      <div
        class="d-flex flex-wrap align-items-stretch justify-content-strat gap-3"
      >
        <div
          class="col cursor-pointer"
          v-for="(item, i) in particularite"
          :key="i"
          @click="chooseUpdate(item)"
          :class="{
            'border border-primary border-2':
              item.id_particularite == form.id_particularite,
          }"
        >
          <div class="card shadow bg-dark text-light border-0 h-100">
            <img
              :src="URL_IMAGE + item.img_particularite"
              class="card-img-top"
              alt="..."
            />
            <div class="card-body">
              <h5 class="card-title text-primary">
                {{ item.titre_particularite }}
              </h5>
              <p class="card-text d-flex">
                {{ item.description_particularite }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </article>
    <div class="mt-4">
      <h2>Modification</h2>
      <hr />
      <form class="row g-3 mb-4" @submit.prevent="confirm">
        <div class="col-12">
          <label for="inputMail1" class="form-label">Titre</label>
          <input
            type="text"
            class="form-control"
            v-model="form.titre_particularite"
          />
          <!-- <div
            v-if="submitted && $v.form.email_faq.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.email_faq.required"
              >Veuillez insérer votre adresse mail</span
            >
            <span v-if="!$v.form.email_faq.email"
              >Veuillez insérer une adresse mail valide</span
            >
          </div> -->
        </div>
        <div class="col-12">
          <label for="inputMail1" class="form-label"
            >Image d'illustration</label
          >
          <input
            type="file"
            class="form-control"
            accept="image/jpeg, image/png"
            @change="changeFile"
          />
          <!-- <div
            v-if="submitted && $v.form.email_faq.$error"
            class="invalid-feedback"
          >
            <span v-if="!$v.form.email_faq.required"
              >Veuillez insérer votre adresse mail</span
            >
            <span v-if="!$v.form.email_faq.email"
              >Veuillez insérer une adresse mail valide</span
            >
          </div> -->
        </div>
        <div class="col-12">
          <label for="inputMessage" class="form-label">Description</label>
          <textarea
            class="form-control"
            id="inputMessage"
            rows="3"
            style="resize: none"
            v-model="form.description_particularite"
          ></textarea>
          <!-- <div
            v-if="submitted && !$v.form.message_faq.required"
            class="invalid-feedback"
          >
            Veuillez insérer votre message ici
          </div> -->
        </div>

        <div class="col-12 mb-2 mt-4 d-flex justify-content-between">
          <!-- <button
            v-if="loading.send"
            class="btn btn-primary"
            type="button"
            disabled
          >
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Envoi...
          </button> -->
          <button v-if="loading" class="btn btn-primary" type="button" disabled>
            <span
              class="spinner-grow spinner-grow-sm"
              role="status"
              aria-hidden="true"
            ></span>
            Envoi...
          </button>
          <button v-else type="submit" class="btn btn-primary">Modifer</button>
        </div>
      </form>
    </div>
  </section>
</template>

<script>
import {
  getAllParticularite,
  updateParticularite,
} from "../../api/apiParticularite";
import { URL_IMAGE } from "../../configs/index";
import { success, error } from "../../utils/toast";

export default {
  name: "ParticulariteListe",
  components: {},
  data() {
    return {
      loading: false,
      URL_IMAGE: URL_IMAGE,
      particularite: [],
      form: {
        id_particularite: 0,
        description_particularite: "",
        image: null,
        titre_particularite: "",
      },
    };
  },
  mounted() {
    getAllParticularite().then((result) => {
      this.particularite = result.data;
    });
  },
  methods: {
    chooseUpdate(data) {
      this.form = {
        id_particularite: data.id_particularite,
        description_particularite: data.description_particularite,
        image: null,
        titre_particularite: data.titre_particularite,
      };
    },
    changeFile(event) {
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = (e) => {
        const base64 = e.target.result;
        this.form.image = base64;
      };
    },
    confirm() {
      if (this.form.id_particularite != 0) {
        this.loading = true;
        updateParticularite(this.form.id_particularite, this.form).then(
          (result) => {
            console.log(result);
            if (result.data.error) {
              error(result.data.error);
            } else {
              getAllParticularite().then((result) => {
                this.particularite = result.data;
              });
              success("Modification réussi");
              this.loading = false;
            }
          }
        );
      } else {
        error("Choisissez une particularité");
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
