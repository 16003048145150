import { BASE_URL } from "../configs";
import { axiosInstanceCollab } from "./config";

const URL = BASE_URL + "/particularite";

export const getParticulariteById = (id) => {
  return axiosInstanceCollab.get(`${URL}/${id}`);
};

export const getAllParticularite = () => {
  return axiosInstanceCollab.get(`${URL}`);
};

export const updateParticularite = (id, data) => {
  return axiosInstanceCollab.put(`${URL}/${id}`, data);
};
